export const trimTrailingZero = (num: string) => {
  const [intPart, fracPart] = num.split(".");
  if (!fracPart) {
    return num;
  }

  const updatedFracPart = fracPart.replace(/0*$/, "");
  if (updatedFracPart.length > 0) {
    return `${intPart}.${updatedFracPart}`;
  }

  return intPart;
};

export const zeroPadding = (length: number) => {
  return "0".repeat(length);
};

const extractDigitsFromValue = (
  value: string,
  amountOfDigitToExtract: number
) => {
  return value.slice(0, amountOfDigitToExtract);
};

const toCommaValue = (
  value: number | string,
  maxDecimalPlaces = 2,
  trimTrailingZeros = false
) => {
  const valueToString = String(value);
  if (valueToString.includes("e-")) {
    // 5.138373046191456e-8 WOULD BE -> 0.00000005
    const [integer, exponent] = valueToString.split("e-");
    if (+exponent > maxDecimalPlaces) {
      return `0.${zeroPadding(maxDecimalPlaces)}`;
    }

    const val = `0.${zeroPadding(+exponent - 1)}${extractDigitsFromValue(
      integer.replace(".", ""),
      maxDecimalPlaces - (+exponent - 1)
    )}`;

    return val;
  } else {
    let [integral, fraction] = valueToString.split(".");
    integral = integral.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (fraction && fraction.length <= maxDecimalPlaces) {
      fraction = fraction.length === 1 ? fraction + "0" : fraction;
    } else {
      fraction = Number(value).toFixed(maxDecimalPlaces).split(".")[1];
    }
    const newValue = [integral, fraction].join(".");
    return trimTrailingZeros ? trimTrailingZero(newValue) : newValue;
  }
};

export default toCommaValue;
