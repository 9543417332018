import { FC } from "react";
import { Menu } from "react-ionicons";
import { useWindowWidth } from "@react-hook/window-size";
import { OnboardLogo } from "../Icons";
import { screenLg } from "../../constants/screen";
import Avatar from "./components/Avatar";

interface NavbarProp {
  collapsed: boolean;
  toggle: () => void;
}

const Navbar: FC<NavbarProp> = ({ collapsed, toggle }) => {
  const width = useWindowWidth();

  return (
    <nav
      className={`transition-all duration-500 py-3 shadow-[0px_1px_2px_rgba(0,0,0,0.07)] bg-white`}
    >
      <div className="flex justify-between items-center max-w-screen-2xl mx-auto px-7">
        <div className="flex">
          {width <= screenLg || collapsed ? (
            <a className="flex items-center mr-4" href="/">
              <OnboardLogo />
            </a>
          ) : null}

          <button className="cursor-pointer" onClick={toggle}>
            <Menu />
          </button>
        </div>
        <div className="flex gap-x-7 items-center">
          <Avatar />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
